export function ProjectCard({
  heroImage,
  date,
  title,
  description,
  imageQuantity,
  onClick,
}) {
  function dateToMonthYear(date) {
    if (!date) return "Sem data";
    const finalDate = new Date(date);

    return `${finalDate.getMonth()}/${finalDate.getFullYear()}`;
  }

  return (
    <>
      <div className="project bg-gray-100 rounded rounded-md" onClick={onClick}>
        <div
          className="project-thumbnail"
          style={{ backgroundImage: `url(${heroImage})` }}
        >
          <div className="absolute top-2 left-2 text-xs px-3 bg-yellow-400 text-white rounded-sm inline">
            {imageQuantity} imagens
          </div>
        </div>
        <div className="project-info p-2">
          <div className="project-title text-ms text-gray-700">{title}</div>
          <div className="project-date text-xs text-gray-400">
            {dateToMonthYear(date)}
          </div>
        </div>
      </div>
    </>
  );
}
