import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createClient, Provider } from "urql";

const client = createClient({
  url: "https://graphql.contentful.com/content/v1/spaces/uuarmmn8sq4a",
  fetchOptions: () => {
    return {
      headers: {
        Authorization: "Bearer puuZ0XWrnYfPGxJM_JDUGSBPDWbRLAY9_YQUc_Miz_U",
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
