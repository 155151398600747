import { useQuery } from "urql";
import "./App.css";
import { ProjectCard } from "./components/ProjectCard";
import { useState } from "react";
import ImgsViewer from "react-images-viewer";
import headerImg from "./header.webp";

const PageQuery = `
  query {
    blogPostCollection {
      items {
        title
        description
        publishDate
        heroImage {
          title
          url
        }
        galleryCollection {
          items {
            title
            url
          }
        }
      }
    }
    homeCollection {
      items {
        value
        slug
      }
    }
  }
`;

function App() {
  const whatsappLink = `https://wa.me/555196685998`;

  const [viewer, setViewer] = useState(false);
  const [images, setImages] = useState([]);
  const [currImg, setCurrImg] = useState(0);

  const [projectResult] = useQuery({
    query: PageQuery,
  });

  const { data, fetching, error } = projectResult;

  if (fetching) return <div className="loading">Carregando...</div>;
  if (error) return <div>Tente novamente</div>;

  function handleViewImages(images) {
    setImages(
      images.map((item) => ({
        src: item.url,
      }))
    );
    setViewer(true);
    setCurrImg(0);
  }

  function getHomeKey(key) {
    return data.homeCollection.items.find((item) => item.slug === key)?.value;
  }

  return (
    <div className="App">
      {!!images.length && (
        <ImgsViewer
          imgs={images}
          isOpen={viewer}
          onClose={() => setViewer(false)}
          onClickPrev={() => setCurrImg((curr) => (curr -= 1))}
          onClickNext={() => setCurrImg((curr) => (curr += 1))}
          currImg={currImg}
        />
      )}

      <header
        style={{
          backgroundImage: `url(${headerImg})`,
        }}
      >
        <div className="information animate__animated animate__fadeInLeft">
          <div className="info-brand text-gray-700">S&G</div>
          <div className="info-information text-gray-600 text-lg text-center">
            {getHomeKey("header-about")}
            <br />
          </div>
          <div className="text-gray-400 text-md text-center">
            {getHomeKey("cnpj")}
          </div>
          <div className="info-icon">
            <div className="icon-scroll"></div>
          </div>
        </div>
      </header>

      <div className="content">
        <section>
          <div className="section-content">
            <div className="flex">
              <div className="rounded-md flex-1 bg-gray-100 p-5 justify-content items-center">
                <div className="md:text-lg sm:text-sm text-gray-600">
                  Projetos concluidos
                </div>
                <div className="text-4xl font-bold text-gray-700 mt-2">
                  {getHomeKey("project-total")}
                </div>
              </div>
              <div className="rounded-md flex-1 bg-gray-100 p-5 justify-content items-center ml-2">
                <div className="md:text-lg sm:text-sm text-gray-600">
                  Projetos em andamento
                </div>
                <div className="text-4xl font-bold text-gray-700 mt-2">
                  {getHomeKey("project-current")}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="section-content">
            <h1 className="main-title">Portfólio</h1>

            <p className="text-center text-gray-700 text-sm">
              {getHomeKey("project-description")}
            </p>

            <div className="project-list">
              {data &&
                data.blogPostCollection.items.map((item, index) => (
                  <ProjectCard
                    key={index}
                    heroImage={item.heroImage.url}
                    title={item.title}
                    date={item.publishDate}
                    description={item.description}
                    imageQuantity={item.galleryCollection?.items.length || 0}
                    onClick={() => {
                      if (item.galleryCollection?.items.length) {
                        handleViewImages(item.galleryCollection.items);
                      }
                    }}
                  />
                ))}

              <div className="project project-empty bg-gray-100 rounded rounded-md text-gray-500">
                Estamos trabalhando no próximo projeto!
              </div>
            </div>
          </div>
        </section>

        <section className="bg-green-100 py-10">
          <div className="section-content">
            <h1 className="main-title">Contato</h1>
            <p className="text-center text-lg text-gray-700 mt-3">
              {getHomeKey("contact-description")}
            </p>
            <div className="flex justify-center mt-3">
              <a
                href={whatsappLink}
                rel="noreferrer"
                target="_blank"
                className="rounded bg-green-300 px-3 py-2 text-md text-green-800 hover:bg-green-600 hover:text-white"
              >
                Mandar mensagem
              </a>
            </div>
          </div>
        </section>
      </div>

      <footer className="text-center bg-gray-100 text-sm p-3 text-gray-400">
        S&G@2021 Capão da canoa
      </footer>
    </div>
  );
}

export default App;
